@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

body {
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

main {
  text-align: center;

  img {
    margin: 50px 0;
    width: 210px;
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;

    + h3 {
      font-weight: 400;
    }
  }

  p {
    margin-top: 100px;
    font-weight: 500;
  }

  #form-messages {
    p {
      margin: 0;
    }
  }

  .input-group {
    display: flex;
    justify-content: center;

    button,
    input {
      letter-spacing: 0.8px;
      font-size: 12px;

      &:active,
      &:focus {
        outline: 0;
      }

    }

    input {
      padding: 0 0 0 15px;
      height: 50px;
      border: 0;
      background-color: #ebebeb;
      margin-right: 5px;
      color: black;
      font-weight: 700;
      width: 250px;

      &::placeholder {
        color: #959595;
        text-transform: uppercase;
      }
    }

    button {
      height: 50px;
      border: 0;
      border-radius: 0;
      background-color: #4CFE57;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0 30px;
      transition: color 200ms ease-in, background-color 200ms ease-in;

      &:focus,
      &:hover {
        transition: color 200ms ease-in, background-color 200ms ease-in;
        cursor: pointer;
        background-color: black;
        color: white;
      }
    }
  }

}
